var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", { staticClass: "col-xl-6 pl-0 pr-0" }, [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.validateBeforeSubmit.apply(null, arguments)
          }
        }
      },
      [
        _c("h1", [_vm._v("Add New Vendor Or WSS")]),
        _c("p", { staticClass: "text-muted" }, [
          _vm._v("Enter email address to invite to signup. Once user is "),
          _c("strong", [_vm._v("signed up")]),
          _vm._v(" they will appear in your wss or vendor lists.")
        ]),
        _c(
          "b-input-group",
          { staticClass: "mt-3 mb-2" },
          [
            _c(
              "b-input-group-prepend",
              [_c("b-input-group-text", [_vm._v("@")])],
              1
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "Email",
                name: "email",
                autocomplete: "email"
              },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ],
          1
        ),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("email"),
              expression: "errors.has('email')"
            }
          ],
          staticClass: "fa fa-exclamation-triangle"
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("email"),
                expression: "errors.has('email')"
              }
            ],
            staticClass: "help is-danger"
          },
          [_vm._v(_vm._s(_vm.errors.first("email")))]
        ),
        _c(
          "b-form-group",
          { staticClass: "mt-1" },
          [
            _c(
              "b-form-radio-group",
              {
                attrs: {
                  stacked: "",
                  id: "radios2",
                  name: "radioSubComponent"
                },
                model: {
                  value: _vm.status,
                  callback: function($$v) {
                    _vm.status = $$v
                  },
                  expression: "status"
                }
              },
              [
                _c("b-form-radio", { attrs: { value: "0" } }, [
                  _vm._v("Vendor")
                ]),
                _c("b-form-radio", { attrs: { value: "1" } }, [_vm._v("WSS")])
              ],
              1
            )
          ],
          1
        ),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error"
            }
          ],
          staticClass: "fa fa-exclamation-triangle"
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.error,
                expression: "error"
              }
            ],
            staticClass: "mb-3 help is-danger"
          },
          [_vm._v("Error. Email already exists. Please contact admin.")]
        ),
        _c(
          "b-button",
          {
            staticClass: "mt-4",
            attrs: { variant: "success", type: "submit", block: "" }
          },
          [_vm._v("Add Email")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }